// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/common/Button.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/common/Button.tsx");
  import.meta.hot.lastModified = "1708984159613.9106";
}
// REMIX HMR END

import React from 'react';
import classNames from 'classnames';
import { useNavigate } from '@remix-run/react';
export default function Button(props) {
  _s();
  const navigate = useNavigate();
  const {
    disabled,
    theme,
    highlight,
    children,
    className,
    ...rest
  } = props;
  return <button {...rest} className={classNames(`min-w-24 justify-center  px-4 py-2 rounded-2xl flex flex-row gap-2 items-center`, {
    'shadow-neon-green': highlight
  }, {
    'bg-primary-500 hover:bg-transparent border-2 border-primary-500 text-white': (!theme || theme === 'primary') && !disabled
  }, {
    'bg-dark-300 hover:bg-transparent border-2 border-dark-300 text-white': theme === 'secondary' && !disabled
  }, {
    'bg-transparent hover:bg-primary-500 border-2 border-primary-500 text-white': theme === 'tertiary' && !disabled
  }, {
    'bg-transparent hover:bg-danger-500 border-2 border-danger-500 text-white': theme === 'danger' && !disabled
  }, {
    'bg-dark-300 text-dark-200': disabled
  }, className)} onClick={e => {
    if (props.onClick) {
      props.onClick(e);
      return;
    }
    if (props.to) {
      e.preventDefault();
      navigate(props.to);
    }
  }}>
      {children}
    </button>;
}
_s(Button, "CzcTeTziyjMsSrAVmHuCCb6+Bfg=", false, function () {
  return [useNavigate];
});
_c = Button;
var _c;
$RefreshReg$(_c, "Button");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;